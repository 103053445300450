* {
  font-family: Nunito sans;
  margin: 0;
  padding: 0;
  text-decoration: none;

}

@font-face {
  font-family: 'Stella';

  src: local('Stella');
  src: url('./Stella.ttf') format("truetype")
}


.logoText {
  font-size: 30px;
  color: blueviolet;
  letter-spacing: .03cm;
  font-family: "Stella";
  cursor: pointer;
}


// .Home {
//   overflow: hidden;
// }

// .App{
//   overflow: hidden;
// }

body {
  overflow: auto;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

.rh {
  color: white;
}


.tr {
  width: calc(100% - .8cm);
  min-height: 3cm;
  background-color: transparent;
  color: white;

  &::placeholder {
    color: rgb(183, 183, 183);
  }
}

.rmbox {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // border: 1px solid black;
  padding: .5cm 10vw 1cm 10vw;

  &::before {
    position: absolute;
    content: "";
    inset: 0;
    // background-color: rgb(235, 212, 255);
    background-image: url("./feedback.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
    filter: brightness(40%);

  }
}



.rboxx {
  backdrop-filter: blur(2px);
  width: fit-content;
  display: flex;
  justify-content: center;

}

.review-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .3cm;
  width: 20cm;
}

.rp {
  width: 100%;
}

// home.jsx
.home-box {
  margin-bottom: 2cm;
}

.main-box {


  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./home.jpg");
    background-position: center;
    background-size: cover;
    z-index: -1;
    filter: brightness(38%);

  }
}

.main-box-sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5cm;
  padding: 1.5cm 10vw 3cm 10vw;
}

.left-main-box {
  width: 15cm;
}

.home-buttons {
  display: flex;
  margin-top: .5cm;
  align-items: center;
  // justify-content: center;
  gap: .5cm;


}


.adopt-head {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // gap: 1cm;

  position: relative;


  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./adopt.jpg");
    background-position: center;
    background-size: cover;
    z-index: -1;
    filter: brightness(25%);

  }
}

.adopt-head-sub {
  padding: 1.5cm 10vw 3cm 10vw;
}

.home-button {
  padding: .35cm 1cm;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.helpline {
  border: 2px solid rgb(4, 136, 4);
  // border: none;
  background-color: rgb(4, 136, 4);
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  color: white;
  box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
  cursor: pointer;
  transition: .3s;

  &:active {
    box-shadow: none;
  }
}

.donate {
  border: 2px solid blueviolet;
  // border: none;
  background-color: blueviolet;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  color: white;
  box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
  cursor: pointer;
  transition: .3s;

  &:active {
    box-shadow: none;
  }
}

.tagline {
  font-size: 60px;
  // font-weight: 600;
  font-family: tilt neon;
  color: white;
  line-height: 1.8cm;
}

.left-main-box-text {
  margin-top: .2cm;
  font-size: 18px;
  color: white;
}

.right-main-box {
  width: 12cm;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 500px;
}

.right-img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 500px;
  border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;
  height: 100%;
}

.home-lower-box {
  padding: 1cm 10vw;
}

.heading {
  font-family: dancing script;
  font-size: 40px;
}

.whatWeDoBoxes {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3cm;
  margin-top: .5cm;
}

.whatWeDoBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1cm;
}

.whatWeDoRight {
  width: 8cm;
  height: 8cm;
  border-radius: 500px;
  flex-shrink: 0;
}

.subHead {
  font-family: tilt neon;
  font-size: 40px;
  margin-bottom: .2cm;
}

.subText {
  font-size: 18px;
}

.rescue {
  width: 100%;
  height: 100%;
  border-radius: 500px;
  border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;
}

.recovery {
  text-align: right;
}

.home-sub-button-box {
  display: flex;
  justify-content: flex-end;
  margin-top: .5cm;
}

.donate-button {
  padding: .3cm .6cm .3cm .6cm;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: blueviolet;
  color: white;
  box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
  cursor: pointer;
  transition: .3s;

  &:active {
    box-shadow: none;
  }

}

.release-div {
  justify-content: flex-start;
}

.release-button {
  border: 3px solid forestgreen;
  background-color: forestgreen;
}

.healed-hearts-main-box {
  margin-top: 3cm;
}

.healed-hearts-box {
  display: flex;
  justify-content: center;
  gap: .5cm;
  flex-wrap: wrap;
  margin-top: 1cm;
}

.healed-heart {
  width: 7cm;
  height: auto;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 10px;
}

.hh-uper {
  width: 100%;
  padding: .2cm;
}

.read-more-stories {
  margin-top: 1cm;
  padding: .4cm 1cm;
  font-size: 16px;
  background-color: rgb(4, 136, 4);
  color: white;
  // border: 2px solid rgb(4, 136, 4);
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
  cursor: pointer;
  transition: .3s;

  &:active {
    box-shadow: none;
  }
}

.healedImg {
  width: calc(100% - 0.4cm);

  height: auto;
  border-radius: 10px 10px 0px 0px;
}

.hh-lower {
  padding: .2cm .2cm .3cm .2cm;
  background-color: rgb(238, 238, 238);
  border-radius: 0px 0px 10px 10px;
}

.hh-date {
  font-size: 13px;
  font-weight: 600;
  color: blueviolet;
}

.hh-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /* Limiting to four lines */
  text-overflow: ellipsis;
}

.hh-watch {
  width: 100%;
  padding: .2cm .5cm;
  font-size: 16px;
  // border: 3px solid blueviolet;
  border: none;
  background-color: blueviolet;
  color: white;
  border-radius: 5px;
  margin-top: .5cm;
  box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
  cursor: pointer;
  transition: .3s;

  &:active {
    box-shadow: none;
  }
}

.stats-main-box,
.rmbox {
  margin-top: 3cm;
}

.stats-box {
  margin-top: 1cm;
  display: flex;
  justify-content: space-evenly;
  gap: .5cm;
  flex-wrap: wrap;
}

.statOne {
  position: relative;
  width: 8cm;
  height: 8cm;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;
  cursor: pointer;
  // box-shadow: 0px 2  px 8px 3px rgb(223, 188, 255);

  &::before {
    inset: 0;
    content: "";
    z-index: -1;
    filter: brightness(35%);
    border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;
    position: absolute;
    background-image: url("./amb.jpg");
    background-position: center;
    background-size: cover;
    transition: transform .3s ease;
  }

  &:hover::before {
    transform: scale(1.01);
  }
}

.statTwo {
  position: relative;
  width: 8cm;
  height: 8cm;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;
  cursor: pointer;
  // box-shadow: 0px 2  px 8px 3px rgb(223, 188, 255);

  &::before {
    inset: 0;
    content: "";
    z-index: -1;
    filter: brightness(35%);
    border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;
    position: absolute;
    background-image: url("./shel.jpg");
    background-position: center;
    background-size: cover;
    transition: transform .3s ease;
  }

  &:hover::before {
    transform: scale(1.01);
  }
}

.statThree {
  position: relative;
  width: 8cm;
  height: 8cm;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;
  cursor: pointer;
  // box-shadow: 0px 2  px 8px 3px rgb(223, 188, 255);

  &::before {
    inset: 0;
    content: "";
    z-index: -1;
    filter: brightness(35%);
    border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;
    position: absolute;
    background-image: url("./staff.jpg");
    background-position: center;
    background-size: cover;
    transition: transform .3s ease;
  }

  &:hover::before {
    transform: scale(1.01);
  }
}

.statText {
  font-size: 30px;
  color: white;
  text-align: center;
  padding: .2cm;
  line-height: .8cm;
}



.payment-sec {

  width: 13cm;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: .3cm;
  margin-top: .4cm;




}

.bank-dets-box {
  margin-top: .15cm;
}

.bank-dets {
  font-size: 18px;
}

.adopt-head-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // gap: .1cm;
  width: 100%;
}

.sub-tag {
  max-width: 22cm;
  color: white;
  text-align: center;
  margin-top: .5cm;
}

.adopt-lower {
  padding: 1cm 10vw 2cm 10vw;


}

.adopt-lower-head {
  display: flex;
  flex-direction: column;
  gap: .3cm;
}

.adoptions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: .5cm;
  margin-top: 1cm;
}

.imp-headR {
  font-weight: 600;
  font-style: italic;
}

.adopt {
  width: 7cm;
  height: auto;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 10px;
}

.adopt-img {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0px 0px;
}

.adopt-box-head {
  padding: .1cm;
}

.adopt-box-lower {
  background-color: rgb(238, 238, 238);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .1cm;
  padding: .1cm .3cm .2cm .3cm;
}

.adopt-head-name {
  // font-size: 18px;
  font-weight: 600;
}

.adopt-dets {
  display: flex;
  align-items: center;
  gap: .15cm;
}

.adopt-age {
  padding: 0cm .15cm 0cm 0cm;
  border-right: 1px solid rgb(79, 79, 79);

  font-size: 14px;

}

.adopt-gen {
  font-size: 14px;
}

.adopt-buttons {
  margin-top: .5cm;
  display: flex;
  flex-direction: column;
  gap: .2cm;

  width: 100%;
}

.adopt-button {
  border: none;
  background-color: blueviolet;
  color: white;
  padding: .15cm .3cm;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
}

.ad {
  background-color: forestgreen;
}

.adopt-new {
  width: 9cm;

  // border: 1px solid rgb(205, 255, 205);

  border: 1px solid rgb(235, 212, 255);

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  border-radius: 10px;
  display: flex;
  flex-direction: column;



}

.adopt-new-name {
  padding: .15cm .5cm;
  // border: 1px solid black;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  background-color: white;
  // color: rgb(56, 255, 56);
  font-size: 18px;
  font-weight: 600;
}

.adopt-new-lower-box {
  // background-color: rgb(223, 255, 223);
  background-color: rgb(243, 229, 255);

  border-radius: 0px 0px 10px 10px;
  padding: .3cm .3cm;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;
  gap: .3cm;
  height: 100%;

}



.adopt-new-img {
  width: 5cm;
  border-radius: 56% 44% 46% 54% / 41% 44% 56% 59%;

  // border: 1px solid black;
}

.adopt-new-buttons {
  display: flex;
  flex-direction: column;
  gap: .3cm;
  margin-top: .3cm;
  width: 100%;
}


.adopt-new-dets {
  font-size: 15px;
}

.adopt-new-button {
  padding: .2cm .8cm;
  border: 2px solid blueviolet;
  // border: none;
  // background-color: blueviolet;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s;
  width: 100%;

}

.adn {

  background-color: transparent;
  color: black;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  cursor: pointer;
  transition: .3s;

  &:active {
    box-shadow: none;
  }
}

.spn {

  background-color: blueviolet;
  color: white;
  width: 100%;
  box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
  cursor: pointer;
  transition: .3s;

  &:active {
    box-shadow: none;
  }
}

.dh {



  &::before {

    background-image: url("./d.jpg");
    filter: brightness(25%);


  }
}

.imp-head {
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}

.key-donate {
  margin: 0.05cm 0cm 0cm 1.5cm;
}

.note {
  font-weight: 600;
}

.donText {
  font-size: 18px;
}

.keyd {
  font-size: 18px;
}

.donation-methods {
  margin-top: 1cm;
  // border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 1.5cm;
}

.donation-method {
  display: flex;
  flex-direction: column;
  gap: .3cm;

}

.donation-method-head {
  font-size: 18px;
  font-weight: 600;
  // font-style: italic;
  // text-decoration: underline;
}


.redirect {
  width: .4cm;
  height: auto;
}



.wishlist-text {
  color: white;
  // font-size: 18px;
}


.wishlist-link-box {
  display: flex;
  align-items: center;
  gap: .15cm;
  margin-top: .3cm;
  // border: 1px solid grey;
  // background-color: rgb(243, 229, 255);
  background-color: blueviolet;
  border: 2px solid blueviolet;

  width: fit-content;
  padding: .25cm 1cm;
  border-radius: 5px;
  box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
  cursor: pointer;
  transition: .3s;

  &:active {
    box-shadow: none;
  }
}

.donation-method-lower {
  margin-left: 1cm;
}


.pay-inp {
  padding: .3cm ;
  font-size: 16px;
  border: 2px solid rgb(196, 130, 255);
  border-radius: 5px;

  &:focus {
    outline: none;
    border: 2px solid blueviolet;
  }
}

.pay-button {
  background-color: blueviolet;
  color: white;
  border: 2px solid blueviolet;
  box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
  padding: .3cm .5cm;

  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:active {
    box-shadow: none;
  }

}

.disabled {
  pointer-events: none;
  opacity: .6;
}

.bold-det {
  font-weight: 600;
}

.qr-codes {
  margin-top: .2cm;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: .3cm;
}

.qr-code {
  width: 5cm;
  height: auto;
}

.Nav {
  padding: .3cm 10vw 0.3cm 10vw;

  // border: 1px solid white;
}

.nav-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.menu {
  display: flex;
  align-items: center;
  gap: .5cm;
}

.menu-item {
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.logoImg {
  width: 3.5cm;
  height: auto;
}


.resident-head {
  &::before {
    background-image: url("./rsd.jpg");
    filter: brightness(33%);
  }
}


.about-box {
  &::before {
    background-image: url("./about.jpg");
    filter: brightness(30%);
  }
}

.contact-head {
  &::before {
    background-image: url("./ct.jpg");
    filter: brightness(33%);
  }
}

.privacy-head {

  &::before {
    background-image: url("./ph.jpg");
    filter: brightness(33%);
  }
}

.small {
  color: red;
}

.contactB {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0cm;
}

.contact-box {
  display: flex;
  flex-direction: column;
  gap: 1cm;
  align-items: center;

}

.social-media-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .4cm;
}

.social-media-boxa {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .2cm;
}

.smi {
  width: 1cm;
  height: auto;
  cursor: pointer;
}

.bt {
  font-weight: 600;

}

.content-box {
  display: flex;
  flex-direction: column;
  gap: 1cm;
}

.yt-video {
  width: 100%;
  aspect-ratio: 16/9;
  border: none;
  border-radius: 10px;
}

.about-sub {
  align-items: center;
  // border: 1px solid rgb(179, 27, 27);
}

.about-right {
  display: flex;
  align-items: center;
}

.about-content {
  padding: 1cm 10vw 2cm 10vw;
  display: flex;
  flex-direction: column;
  gap: 2cm;
}

.about-div {
  display: flex;
  flex-direction: column;
  gap: .2cm;
  align-items: center;
}

.content-about {
  text-align: center;
  font-size: 18px;
}

.db {
  cursor: pointer;
}

.wt {
  color: white;
}

.menuImg {
  width: .6cm;
  height: auto;
  cursor: pointer;
  display: none;
  user-select: none;
}


.overlayNav {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;

  backdrop-filter: blur(10px);
  z-index: 1;
  width: 7cm;
  // transition: .05s ease-in-out;
}

#overlayNav {
  width: 0cm;
}

.overlay-nav-box {
  background-color: rgb(44, 44, 44);
  height: calc(100% - 2cm);
  overflow-y: auto;
  padding: 1cm;
  display: flex;
  flex-direction: column;
  gap: 1cm;
}

.close {
  width: .5cm;
  height: auto;
  cursor: pointer;

}

.overlay-menu {
  display: flex;
  flex-direction: column;
  gap: .5cm;
  align-items: center;
}

.overlay-menu-item {
  font-size: 18px;
  color: white;
  padding: .25cm 1cm;
  width: fit-content;
  border: 1px solid rgb(66, 66, 66);
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background-color: rgb(73, 73, 73);
    border: 1px solid rgb(73, 73, 73);
  }
}

.close-box {
  display: flex;
  justify-content: flex-end;
}

.overlayDiv {
  position: fixed;
  inset: 0;
  overflow-y: auto;
  backdrop-filter: blur(40px);
  // background-color: rgba(255, 255, 255, 0.433);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2cm .5cm;
  display: none;
}

.overlayAdopt {
  width: fit-content;
  padding: .5cm;
  border-radius: 10px;
  height: auto;
  background-color: rgb(243, 229, 255);
  display: flex;
  flex-direction: column;
  gap: .2cm;
  border: 1px solid rgb(214, 168, 255);
}

.head-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .3cm;
}

.af {
  background-color: transparent;
}

.cross {
  width: .4cm;
  height: auto;
  cursor: pointer;
}

.adopt-butns-div {
  display: flex;
  gap: .3cm;
}

.success {
  width: 2.5cm;
  height: auto;
}

.thanks-box {
  align-items: center;
  gap: .1cm;
  margin-top: 0cm;
}

.thanksText {
  font-size: 20px;
  text-align: center;
  line-height: .65cm;
}

#thanksBox {
  display: none;
}

.teamText {
  font-size: 16px;
  text-align: center;
}

.okay {
  padding: .2cm .6cm;
  background-color: blueviolet;
  color: white;
  font-size: 16px;
  border: 2px solid blueviolet;
  border-radius: 50px;
  margin-top: .5cm;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background-color: transparent;
    color: black;
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  transition: hover .3s;


  // display: inline-block;
}

.drpbox {
  display: flex;
  align-items: center;
  gap: .7cm;
  justify-content: space-between;
  padding: .3cm .4cm;
  cursor: pointer;



  // padding: 16px;
  transition: .3s;
  border-radius: 5px 5px 5px 5px;
  // background-color: rgb(204, 149, 255);
  background-color: transparent;
  border: 2px solid rgb(196, 130, 255);



}

/* Dropdown Content (Hidden by Default) */
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: block;

  position: absolute;
  height: fit-content;

  overflow-y: auto;


  transition: 0.3s ease;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;


}

/* Show the dropdown menu on hover */



/* Links inside the dropdown */
.dropdown-content p {
  color: black;
  padding: .2cm .2cm;
  text-decoration: none;
  display: block;
  cursor: pointer;
  border-bottom: 1px solid rgb(206, 206, 206);
  transition: .3s ease;
  font-size: 15px;



  &:hover {
    background-color: rgb(220, 220, 220);
  }

}

.da {
  width: .3cm;
  height: auto;
  transition: .3s ease;
}

.errorText {
  color: red;
}

#errorBosSponsor {
  display: none;
}

.scroll-up {
  position: fixed;
  z-index: 1;

  color: white;
  // padding: .2cm;
  border-radius: 1000px;
  bottom: 0;
  right: 0;
  margin: 0cm 1cm 1cm 0cm;
  box-shadow: 0 2px 4px #0006, 0 7px 13px -3px #0000004d, inset 0 -3px 0 #0003;
  cursor: pointer;
  transition: .3s;
  display: none;

  &:active {
    box-shadow: none;
  }
}

.upA {

  border-radius: 1000px;
  width: 1cm;
  height: auto;
  user-select: none;

}

.Footer {
  margin-top: 2cm;
  background-color: #f0f0f0;
  padding: 0.3cm 10vw 0cm 10vw;

}

.footer-reach {
  margin-top: .5cm;
  font-size: 17px;
  font-weight: 600;
}

.footer-menu {
  display: flex;
  flex-direction: column;
  margin-top: .2cm;
}

.footer-head-sub {
  font-size: 17px;
  font-weight: 600;
}

.footer-icons {
  display: flex;
  margin-top: .1cm;
  align-items: center;
  column-gap: .15cm;
}

.footer-email {
  // font-weight: 600;
}

.footer-icon {
  width: .7cm;
  height: auto;
}


.footer-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding-bottom: .3cm;
  border-bottom: thin solid #c4c4c4;


}

.second-footer {
  display: flex;

  column-gap: 2cm;
}


.company-footer {
  width: fit-content;

}

.footer-logo {
  font-family: Kalam;
  font-size: 30px;
}

.footer-about {
  font-size: 15px;
  margin-top: .1cm;
  color: rgb(48, 48, 48);


}

.footer-email {
  a {
    color: black;
  }
}


.footer-lower-txt {
  font-size: 15px;
  color: rgb(48, 48, 48);


}

.lower-footer-icons {
  display: flex;
  align-items: center;
  column-gap: .13cm;
}

.lower-footer-icon {
  width: .6cm;
  height: auto;
  cursor: pointer;
  transition: .2s all;

  &:hover {
    margin-top: -0.07cm;
  }
}

.lower-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: .3cm;
  padding: .2cm 0cm .2cm 0cm;
}

.footer-menuItem {
  font-size: 15px;
  cursor: pointer;
  color: rgb(48, 48, 48);


}

.right-adopt {
  width: 9cm;
}

@media only screen and (max-width : 1400px) {
  .tagline {
    font-size: 55px;
    line-height: 1.7cm;
  }



  .right-main-box {
    width: 11cm;
  }

  .right-adopt {
    width: 9cm;
  }

  .menu-item {
    font-size: 16px;
  }

  .logoImg {
    width: 3cm;
  }


}

@media only screen and (max-width : 1200px) {
  .tagline {
    font-size: 55px;
  }



  .right-main-box {
    width: 10cm;
  }

  .right-adopt {
    width: 8cm;
  }


  .main-box-sub {

    padding: 1.5cm 5vw 3cm 5vw;
  }

  .Footer {
    padding: .3cm 5vw 0cm 5vw;
  }

  .adopt-head-sub {
    padding: 1.5cm 5vw 3cm 5vw;
  }

  .rmbox {
    padding: .5cm 5vw 1cm 5vw;
  }

  .Nav {
    padding: .3cm 5vw;
  }

  .home-lower-box,
  .adopt-lower {
    padding: 1cm 5vw;
  }

  .left-main-box-text {
    margin-top: .2cm;
    font-size: 16px;
    color: white;
  }

  .healed-hearts-main-box,
  .stats-main-box,
  .rmbox {
    margin-top: 2cm;
  }

  .home-button {
    padding: .3cm .8cm;
  }

  .heading {
    font-size: 35px;
  }

  .subHead {
    font-size: 35px;
  }

  .subText {
    font-size: 16px;
  }

  .donate-button {
    padding: .25cm .5cm .25cm .5cm;

  }

  .whatWeDoRight {
    width: 7cm;
    height: 7cm;
  }

  .adopt-new {
    width: 8cm;
  }

  .adopt-new-name {
    padding: .1cm .5cm;
    font-size: 17px;

  }

  .adopt-new-img {
    width: 4cm;
  }

  .read-more-stories {
    padding: .3cm .8cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 7cm;
    height: 7cm;
  }

  .statText {
    font-size: 26px;
  }

  .whatWeDoBoxes {
    gap: 2cm;
  }

}

@media only screen and (max-width : 1100px) {
  .tagline {
    font-size: 50px;
    line-height: 1.5cm;
  }



  .right-main-box {
    width: 9.5cm;
  }

  .right-adopt {
    width: 8cm;
  }






  .left-main-box-text {
    margin-top: .2cm;
    font-size: 16px;
    color: white;
  }

  .home-button {
    padding: .3cm .8cm;
  }

  .heading {
    font-size: 32px;
  }

  .subHead {
    font-size: 35px;
  }

  .subText {
    font-size: 16px;
  }

  .donate-button {
    padding: .25cm .5cm .25cm .5cm;

  }

  .whatWeDoRight {
    width: 6cm;
    height: 6cm;
  }

  .adopt-new {
    width: 7cm;
  }

  .adopt-new-name {
    padding: .1cm .5cm;
    font-size: 16px;

  }

  .adopt-new-img {
    width: 4cm;
  }

  .read-more-stories {
    padding: .3cm .8cm;
  }

  .hh-watch {
    margin-top: .2cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 6.5cm;
    height: 6.5cm;
  }

  .statText {
    font-size: 24px;
  }

  .adopt-new-button {
    padding: .15cm .6cm;

  }

}

@media only screen and (max-width : 1000px) {
  .tagline {
    font-size: 45px;
    line-height: 1.3cm;
  }





  .adoptions {
    margin-top: 1cm;
  }

  .right-main-box {
    width: 9cm;
  }

  .right-adopt {
    width: 8cm;
  }






  .left-main-box-text {
    margin-top: .2cm;
    font-size: 16px;
    color: white;
  }

  .home-button {
    padding: .2cm .6cm;
  }

  // .heading {
  //   font-size: 30px;
  // }

  // .subHead {
  //   font-size: 33px;
  // }

  .subText {
    font-size: 16px;
  }

  .donate-button {
    padding: .2cm .5cm .2cm .5cm;

  }

  .whatWeDoRight {
    width: 5.5cm;
    height: 5.5cm;
  }

  .adopt-new {
    width: 6.5cm;
  }

  .adopt-new-name {
    padding: .1cm .5cm;
    font-size: 16px;

  }

  .adopt-new-img {
    width: 4cm;
  }

  .read-more-stories {
    padding: .2cm .6cm;
  }

  .hh-watch {
    margin-top: .2cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 6.5cm;
    height: 6.5cm;
  }

  .statText {
    font-size: 24px;
  }

  .adopt-new-button {
    padding: .12cm .6cm;

  }

  .adopt-new-dets {
    font-size: 14px;
  }


  .imp-head {
    font-size: 16px;
  }

  .donText {
    font-size: 16px;
  }

  .keyd {
    font-size: 16px;
  }

  // .donation-method-head {
  //   font-size: 16px;
  // }

  .donation-method {


    gap: .2cm;
  }

  .dm-det {
    font-size: 15px;
  }

  .wishlist-link-box {

    padding: .22cm .8cm;

  }

  .donation-method-lower {
    margin-left: .8cm;
  }

  .contactB {
    margin-left: 0cm;
  }

  .bank-dets {
    font-size: 16px;
  }


}



@media only screen and (max-width : 900px) {
  .tagline {
    font-size: 40px;
    line-height: 1.25cm;
  }

  .logoText {
    font-size: 28px;
  }

  .right-main-box {
    width: 8.5cm;
  }

  .right-adopt {
    width: 7cm;
  }


  .main-box-sub,
  .adopt-head-sub {
    padding: 1cm 5vw 2cm 5vw;
  }

  .home-lower-box,
  .adopt-lower {
    padding: .5cm 5vw;
  }





  .left-main-box-text {
    margin-top: .2cm;
    font-size: 16px;
    color: white;
  }

  .home-button {
    padding: .15cm .5cm;
    font-size: 16px;
  }

  .heading {
    // font-size: 28px;
  }

  .subHead {
    // font-size: 30px;
  }

  .subText {
    font-size: 16px;
  }

  .donate-button {
    padding: .15cm .5cm .15cm .5cm;

  }

  .whatWeDoRight {
    width: 5cm;
    height: 5cm;
  }

  .adopt-new {
    width: 6cm;
  }

  .adopt-new-name {
    padding: .1cm .5cm;
    font-size: 16px;

  }



  .adopt-new-img {
    width: 4cm;
  }

  .read-more-stories {
    padding: .2cm .6cm;
  }

  .hh-watch {
    margin-top: .2cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 6cm;
    height: 6cm;
  }

  .statText {
    font-size: 22px;
  }

  .adopt-new-button {
    padding: .12cm .6cm;

  }

  .adopt-new-dets {
    font-size: 14px;
  }

}


@media only screen and (max-width : 820px) {
  .second-footer {
    flex-direction: column;
    row-gap: .5cm;
  }
}


@media only screen and (max-width : 800px) {
  .tagline {
    font-size: 38px;
    line-height: 1.2cm;
  }

  .right-main-box {
    width: 8cm;
  }

  .right-adopt {
    width: 7cm;
  }


  .main-box-sub,
  .adopt-head-sub {
    padding: 1cm 5vw 2cm 5vw;

  }

  .home-lower-box,
  .adopt-lower {
    padding: .5cm 5vw;
  }





  .left-main-box-text {
    margin-top: .2cm;
    font-size: 16px;
    color: white;
  }

  .home-button {
    padding: .15cm .5cm;
    font-size: 16px;
  }

  .heading {
    // font-size: 28px;
  }

  .subHead {
    // font-size: 30px;
  }

  .subText {
    font-size: 16px;
  }

  .donate-button {
    padding: .15cm .5cm .15cm .5cm;

  }

  .whatWeDoRight {
    width: 5cm;
    height: 5cm;
  }

  .adopt-new {
    width: 6cm;
  }

  .adopt-new-name {
    padding: .1cm .5cm;
    font-size: 16px;

  }



  .adopt-new-img {
    width: 4cm;
  }

  .read-more-stories {
    padding: .2cm .6cm;
  }

  .hh-watch {
    margin-top: .2cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 6cm;
    height: 6cm;
  }

  .statText {
    font-size: 22px;
  }

  .adopt-new-button {
    padding: .12cm .6cm;

  }

  .adopt-new-dets {
    font-size: 14px;
  }

}


@media only screen and (max-width : 700px) {
  .tagline {
    font-size: 42px;
    line-height: 1.4cm;

    text-align: center;
  }

  .right-main-box {
    width: 10cm;
  }

  .right-adopt {
    width: 7cm;
  }


  .main-box-sub {
    padding: 1cm 5vw 2cm 5vw;
    gap: 1.5cm;
    flex-direction: column;

  }

  .about-sub {
    flex-direction: column-reverse;
    gap: .6cm;
  }

  .adopt-head-sub {
    padding: 1cm 5vw 2cm 5vw;
  }


  .left-main-box {
    align-items: center;
    justify-content: center;
    width: 100%;
  }


  .home-lower-box,
  .adopt-lower {
    padding: .5cm 5vw;
  }





  .left-main-box-text {
    margin-top: .2cm;
    font-size: 17px;
    color: white;
    text-align: center;
  }

  .home-buttons {
    justify-content: center;
  }

  .home-button {
    padding: .2cm .7cm;
    font-size: 16px;
  }

  .heading {
    // font-size: 28px;
  }

  .subHead {
    // font-size: 30px;
  }

  .subText {
    font-size: 16px;
  }

  .donate-button {
    padding: .15cm .5cm .15cm .5cm;

  }

  .whatWeDoRight {
    display: none;
  }

  .adopt-new {
    width: 7cm;
  }

  .adopt-new-name {
    padding: .1cm .5cm;
    font-size: 16px;

  }



  .adopt-new-img {
    width: 4cm;
  }

  .read-more-stories {
    padding: .2cm .6cm;
  }

  .hh-watch {
    margin-top: .2cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 6cm;
    height: 6cm;
  }

  .statText {
    font-size: 22px;
  }

  .adopt-new-button {
    padding: .12cm .6cm;

  }

  .adopt-new-dets {
    font-size: 14px;
  }

}


@media only screen and (max-width : 600px) {
  .tagline {
    font-size: 50px;
    line-height: 1.6cm;
    text-align: center;
  }

  .logoText {
    font-size: 26px;
  }

  .footer-reach,
  .footer-head-sub {
    font-size: 16px;
  }

  .company-footer {
    width: 100%;
  }





  .footer-box {
    flex-direction: column;
    row-gap: .5cm;
    // align-items: center;
  }

  .second-footer {
    flex-direction: row;
  }


  .shop-left-box-main {
    width: 100%;
  }

  .Footer {
    padding: 0.3cm 5vw 0cm 5vw;
  }

  .overlayAdopt {
    padding: .3cm;
  }

  .right-main-box {
    width: 100%;
  }

  .thanksText {
    font-size: 18px;
    text-align: center;
    line-height: .65cm;
  }

  .success {
    width: 2cm;
  }

  .main-box-sub {
    padding: 1cm 5vw 2cm 5vw;
    gap: 1.5cm;
    flex-direction: column;

  }

  .content-about {
    font-size: 16px;
  }

  .about-sub {
    flex-direction: column-reverse;
    gap: .6cm;
  }

  .right-adopt {
    width: 8cm;
  }

  .menu-item {
    display: none;
  }

  .pay-inp,
  .pay-button,
  .drpbox {
    padding: .2cm .3cm;
  }

  .tr {
    width: calc(100% - .6cm);
  }

  .menuImg {
    display: flex;
  }




  .left-main-box {
    align-items: center;
    justify-content: center;
    width: 100%;
  }


  .home-lower-box,
  .adopt-lower {
    padding: .5cm 5vw;
  }



  .left-main-box-text {
    margin-top: .2cm;
    font-size: 16px;
    color: white;
    text-align: center;
  }



  .home-buttons {
    justify-content: center;
    flex-direction: column;
    gap: .3cm;
    margin-top: .4cm;
  }

  .home-button {
    padding: .3cm .7cm;
    width: 100%;
    font-size: 16px;
  }

  .hbd {
    width: 100%;
  }

  .heading {
    // font-size: 30px;
  }

  .subHead {
    // font-size: 30px;
    text-align: center;
  }

  .subText {
    text-align: center;
    // font-size: 15px;
  }

  .donate-button {
    padding: .2cm .5cm .2cm .5cm;
    // margin-top: .2cm;
    // display: none;
    width: 100%;

  }


  .whatWeDoRight {
    display: none;
  }

  .adopt-new {
    width: 100%;
  }

  .adopt-new-name {
    padding: .1cm .5cm;
    font-size: 16px;

  }

  .donation-methods {
    gap: 1cm;
  }

  .adopt-new-img {
    width: 5cm;
  }

  .read-more-stories {
    padding: .3cm .6cm;
    width: 100%;
  }

  .hh-watch {
    margin-top: .2cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 8cm;
    height: 8cm;
  }

  .statText {
    font-size: 22px;
  }

  .adopt-new-button {
    padding: .2cm .6cm;

  }

  .adopt-new-dets {
    font-size: 16px;
  }

  .imp-head {
    font-size: 16px;
  }

  .donText {
    font-size: 16px;
  }

  .keyd {
    font-size: 16px;
  }

  // .donation-method-head {
  //   font-size: 16px;
  // }

  .donation-method {


    gap: .2cm;
  }

  .dm-det {
    font-size: 15px;
  }

  .wishlist-link-box {

    padding: .22cm .8cm;

  }

  .donation-method-lower {
    margin-left: 0cm;

  }

  .bank-dets {
    font-size: 16px;
  }

  .key-donate {
    padding: 0cm 5vw;
    margin-left: 0cm;
  }

  .wishlist-link-box {



    padding: .25cm 1cm;
    width: 50vw;
    justify-content: center;


  }

  .payment-sec {
    width: 100%;
  }

  .qr-code {
    width: 4cm;
    height: auto;
  }

  .home-sub-button-box {
    margin-top: .3cm;
  }

  .whatWeDoBoxes {
    margin-top: .25cm;
    gap: 1cm;
  }

  .healed-hearts-box {
    margin-top: .7cm;
  }

  .overlayAdopt {
    width: 100%;
  }

}

@media only screen and (max-width : 500px) {
  .tagline {
    font-size: 45px;
    line-height: 1.4cm;
    text-align: center;
  }

  .right-main-box {
    width: 100%;
  }

  .second-footer {
    column-gap: 1cm;
  }

  .lower-footer-icon {
    width: .45cm;
  }



  .footer-icon {
    width: .5cm;
  }


  .main-box-sub,
  .adopt-head-sub {
    padding: .5cm 5vw 1.5cm 5vw;


  }

  .about-sub {
    flex-direction: column-reverse;
    gap: .6cm;
  }

  .right-adopt {
    width: 7cm;
  }

  .sub-tag {
    font-size: 15px;
    margin-top: .3cm;
  }

  .left-main-box {
    align-items: center;
    justify-content: center;
    width: 100%;
  }


  .home-lower-box,
  .adopt-lower {
    padding: .5cm 5vw;
  }


  .logoImg {
    width: 1cm;
  }


  .left-main-box-text {
    margin-top: .2cm;
    font-size: 18px;
    color: white;
    text-align: center;
  }



  .home-buttons {
    justify-content: center;
    flex-direction: column;
  }

  .home-button {
    padding: .3cm .7cm;
    width: 100%;
    font-size: 16px;
  }

  // .heading {
  //   font-size: 30px;
  // }

  // .subHead {
  //   font-size: 30px;
  // }



  .donate-button {
    padding: .2cm .5cm .2cm .5cm;

  }

  .whatWeDoRight {
    display: none;
  }

  .adopt-new {
    width: 100%;
  }

  .adopt-new-name {
    padding: .1cm .5cm;
    font-size: 16px;

  }



  .adopt-new-img {
    width: 5cm;
  }

  .read-more-stories {
    padding: .3cm .6cm;
    width: 100%;
  }

  .hh-watch {
    margin-top: .2cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 8cm;
    height: 8cm;
  }

  .statText {
    font-size: 22px;
  }

  .adopt-new-button {
    padding: .2cm .6cm;

  }

  .adopt-new-dets {
    font-size: 16px;
  }

  .logoText {
    font-size: 24px;
  }

}

@media only screen and (max-width : 400px) {
  .tagline {
    font-size: 40px;
    line-height: 1.2cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 7cm;
    height: 7cm;
  }

  .heading {
    font-size: 30px;
  }

  .subHead {
    font-size: 30px;
  }

  .logoImg {
    width: .8cm;
  }


  .donation-method-head {
    font-size: 16px;
  }

  .right-adopt {
    width: 6cm;
  }


}

@media only screen and (max-width : 300px) {
  .tagline {
    font-size: 35px;
    line-height: 1.1cm;

  }

  .adopt-butns-div {
    flex-direction: column;
    gap: .25cm;
  }

  .second-footer {
    flex-direction: column;
    column-gap: .5cm;
    // align-items: flex-start;
  }

  .overlayAdopt {
    padding: .5cm .3cm;
  }

  .statOne,
  .statTwo,
  .statThree {
    width: 5cm;
    height: 5cm;
  }

  .right-adopt {
    width: 5cm;
  }


}